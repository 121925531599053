import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "loading" ]
  static targets = [ "fieldset", "button", "submitButton" ]

  markLoading() {
    this.disableFieldsets()
    this.disableButtons()
    this.putSubmitButtonInLoadingMode()
  }

  disableFieldsets() {
    this.fieldsetTargets.forEach((fieldsetTarget) => {
      fieldsetTarget.disabled = true
    })
  }

  putSubmitButtonInLoadingMode() {
    this.submitButtonTarget.classList.add(this.loadingClass)
  }

  disableButtons() {
    const buttons = [ ...this.buttonTargets, this.submitButtonTarget ]
    buttons.forEach((buttonTarget) => {
      buttonTarget.disabled = true
    })
  }
}